import React from "react";
import { Collapse } from "react-collapse";
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io'

const AcordSac = ({open, toogle, question, answer, a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, image}) => {
    
    return (
        <div className="pt-[10px] ">
            <div className="bg-gray-200 py-[25px] px-[50px] flex justify-between items-center cursor-pointer
              hover:bg-blue-500 rounded hover:text-white" onClick={toogle} >
             <p className="text-[22px] font-semibold">{question}</p>
             <div className="text-[30px]">
                {open ? <IoIosArrowUp/> : <IoIosArrowDown />}
             </div>
            </div>

            <Collapse isOpened={open} >
            <img src={image} className="w-[500%] h-[100%] p-10" />
                <div className="bg-white px-[50px] pb-[20px]">{answer}
         </div>
            </Collapse>
        </div>
    )
}
export default AcordSac