// import images from '../../public/images/images' 

 const images = [
  { id: "1", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/gallery%2F1.jpg?alt=media&token=6686be68-b245-409a-a680-c1aa49fda2c3",  tag: "Holy Father" },
  { id: "2", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/King%20of%20Kings%2FFather%208.jpg?alt=media&token=7210bd58-1ef9-4dfa-a106-9a1058427f64", tag: "KOK" },
  { id: "3", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/Members%202.jpg?alt=media&token=60f72022-fa2a-40cb-ad0f-1cba0e0d39be", tag: "Bcs Members" },
  { id: "4", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/gallery%2F13.jpg?alt=media&token=05b05c6a-e0d9-4949-9b03-10699be1bd35", tag: "Holy Father" },
  { id: "5", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/today%2F0005.jpeg?alt=media&token=9c44d2e6-18d5-4987-b14d-5347deee3f6c", tag: "KOK" },
  { id: "6", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/website%2FIMG_2405.JPG?alt=media&token=81be20e8-f842-4ae4-9af7-42a99f69bd0a", tag: "Bcs Members" },
  { id: "7", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/website%2FIMG_2413.JPG?alt=media&token=a71933d1-e42b-416d-83e8-4005d5026b31", tag: "Bcs Members" },
  { id: "8", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/images%2Fgallery01.jpg?alt=media&token=18a61501-ef31-4c5c-926d-865c9ac91656", tag: "KOK" },
  { id: "9", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/website%2FAR3A1645.JPG?alt=media&token=0d0b8ea8-3598-4dd1-9a20-258239b4eb80", tag: "Bcs Members" },
  { id: "10", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/website%2F28.bmp?alt=media&token=cbf75351-2b6b-448b-b5e3-b374ca2f91b7", tag: "Holy Father" },
  { id: "11", imageUrl:"https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/Dumbari%2FDSC_0036%20(2).JPG?alt=media&token=96ba44e9-5450-490d-961a-af635c0f4b5f", tag: "KOK" },
  { id: "12", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/website%2FAR3A1649.JPG?alt=media&token=45bdc7e4-b1d2-464f-b487-f2c34c6f1102", tag: "Bcs Members" },
  { id: "13", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/images%2Fgalery04.JPG?alt=media&token=a0c9f8fc-8323-4598-8974-12f0cc72bf17", tag: "KOK" },
  { id: "14", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/website%2FIMG_2414.JPG?alt=media&token=3bae116b-bd26-45a9-ac02-659f538761c3", tag: "Bcs Members" },
  { id: "15", imageUrl:  "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/images%2Fgalery02.JPG?alt=media&token=8a811c86-3027-4591-9ed4-0460355a22fd", tag: "KOK" },
  { id: "16", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/gallery%2F0.0.0.jpg?alt=media&token=2ce3e222-5506-407f-a350-8ce0452e1909 ", tag: "Holy Father" },
]

export default images