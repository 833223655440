import { Row, Col, Card, Avatar, Typography, Divider, Space, Button, Popconfirm, message } from 'antd';
import { ShareAltOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { db, doc, getDoc } from '../firebase'; 
import QRCode from "react-qr-code";

const { Title, Text } = Typography;

const UserProfile = () => {


  const { userId } = useParams();  // Get the userId from the URL
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const docRef = doc(db, "members", userId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        setUserData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchUserData();
  }, [userId]);


  const handleDownload = () => {
    message.success('QR Code downloaded!');
    const canvas = document.getElementById('qrCodeCanvas');
    if (canvas) {
      const qrImage = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = qrImage;
      link.download = 'profile-qr-code.png';
      link.click();
      message.success('QR Code downloaded!');
    } else {
      message.error('QR Code not available for download');
    }
  };

  const handleEdit = () => {
    message.info('Edit profile feature coming soon!');
  };

  return (
    <div className="bg-gray-100 p-6 min-h-screen">
      <Row gutter={[16, 16]} justify="center" align="top">

        {/*User Info */}
        <Col xs={24} sm={24} md={12} lg={8}>
          <Card className="shadow-lg p-6 rounded-lg bg-white">
            <div className="flex justify-center">
              <img
                // size={120}
                src={userData?.profile_image || "https://via.placeholder.com/150"}
                className="mb-4 w-[120px] h-[120px] rounded-full"
              />
            </div>
            <Title level={3} className="text-center text-xl font-semibold">
              {userData?.first_name && userData?.last_name
                ? `${userData.first_name} ${userData.last_name}`
                : "Not Added"}
            </Title>
            <Text className="block text-center text-lg text-gray-600">
              {`${userData?.pew || "Not Added"} | ${userData?.office && "Office: " + userData?.office  || ""} | ${userData?.officer_at && "At " + userData?.officer_at || ""}`}
            </Text>

            <Divider />
            {/* <Space direction="vertical" size={10}>
              <Text><strong>Email:</strong> {userData?.email || "Not Added"}</Text>
              <Text><strong>Phone Number:</strong> {userData?.phone_number || "Not Added"}</Text>
              <Text><strong>Gender:</strong> {userData?.gender || "Not Added"}</Text>
              <Text><strong>Bethel:</strong> {userData?.bethel || "Not Added"}</Text>
              <Text><strong>State of Origin:</strong> {userData?.state_of_origin || "Not Added"}</Text>
              <Text><strong>Country:</strong> {userData?.country || "Not Added"}</Text>
              <Text><strong>Residential Address:</strong> {userData?.residential_address || "Not Added"}</Text>
              <Text><strong>LGA of Origin:</strong> {userData?.lga_of_origin || "Not Added"}</Text>
              <Text><strong>Home Town:</strong> {userData?.home_town || "Not Added"}</Text>
              <Text><strong>State of Residence:</strong> {userData?.residing_state || "Not Added"}</Text>
              <Text><strong>Nation of Worship:</strong> {userData?.nation_of_worship || "Not Added"}</Text>
            </Space> */}

            {/* <div className="mt-4 flex justify-center">
              <Button
                icon={<EditOutlined />}
                shape="round"
                size="large"
                onClick={handleEdit}
                className="bg-blue-500 text-white"
              >
                Edit Profile
              </Button>
            </div> */}
          </Card>
        </Col>

        {/* QR Code */}
        <Col xs={24} sm={24} md={12} lg={8}>
      <Card className="shadow-lg p-6 rounded-lg bg-white">
        <Title level={4} className="text-center mb-4">User Profile QR Code</Title>
        <div className="flex justify-center">
          {userData ? (
            <QRCode
              id="qrCodeCanvas"
              size={200}
              value={`https://ooobcs.org/profile/${userId || 'defaultUserId'}`}
            />
          ) : (
            <Text className="text-gray-600">QR Code not available</Text>
          )}
        </div>
        <div className="mt-4 mb-2 text-center">
          <Text className="text-gray-600">Scan this QR code to visit the profile page.</Text>
        </div>

        <Space direction="horizontal" className="mt-0 justify-center self-center flex" size="middle">
          
          {/* <Button
            icon={<DownloadOutlined />}
            shape="round"
            size="large"
            onClick={handleDownload}
            className="bg-blue-500 text-white"
          >
            Download
          </Button> */}
        </Space>
      </Card>
    </Col>
      </Row>

  
    </div>
  );
};

export default UserProfile;
